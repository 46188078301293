<template>
    <div class="main-wrapper">
        <div class="main-wrapper-table">
            <div v-if="exam_content === '商品发布' || exam_content === '网店商品发布与管理'" >
                <el-table :data="goodsReleaseList" class="customTable goods-text-table"
                    style="width: 100%; flex: 1; margin-top: 20px;">
                    <el-table-column prop="exam_content" label="考试内容" ></el-table-column>
                    <el-table-column label="学生提交答案" align="left" >
                        <template slot-scope="scope">
                            <div class="answer" v-if="scope.row">
                                <!-- <router-link class="a-link"
                                    :to="{ path: '/judges/goodsDetail', query: { g_id: good_id } }" target="_blank">
                                    <img :src="scope.row.goods_images" alt="">
                                    <span class="text">{{ scope.row.goods_title }}</span>
                                </router-link> -->
                                <img :src="scope.row.goods_images" alt="" @click="gotoDetail" style="cursor:pointer">
                                <span class="text">{{ scope.row.goods_title }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="search_num" v-if="exam_content === '商品发布'" label="搜索人数" align="center">
                    </el-table-column>
                    <el-table-column prop="click_rate" v-if="exam_content === '商品发布'" label="点击率" align="center">
                    </el-table-column>
                    <el-table-column prop="student_click_num" v-if="exam_content === '商品发布'" label="学生点击量"
                        align="center"></el-table-column>
                    <el-table-column prop="full_score_hits" v-if="exam_content === '商品发布'" label="满分点击量"
                        align="center"></el-table-column>
                    <el-table-column prop="stock" v-if="exam_content === '移动平台商品发布与管理'" label="总库存" align="center">
                    </el-table-column>
                    <el-table-column prop="market_price" v-if="exam_content === '移动平台商品发布与管理'" label="销售价" align="center">
                    </el-table-column>
                    <el-table-column :label="`得分(${total_score})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row">{{ scope.row.score }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table :data="goodsShootList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="exam_content" label="考试内容" width="320"></el-table-column>
                    <!-- <el-table-column prop="module" label="考试模块" width="380px"></el-table-column> -->
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDetailGoodsStu(scope.row)">查看详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewDetailGoods(scope.row)">查看详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${freight_info_total_score})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row">{{ scope.row.score }}</span>
        
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else-if="(exam_content === '订单管理'|| !exam_content) && moduleId!=91">
                <el-table :data="goodsOrderList" class="customTable goods-text-table exam-content"
                    style="width: 100%; flex: 1; margin-top: 20px;">
                    <el-table-column label="考试内容" width="220">
                        <template slot-scope="scope">
                            <span v-if="scope.$index === 0">{{ exam_content }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="order_no"  label="订单编号" align="center" width="220"></el-table-column>
                    <el-table-column prop="scoring_operation"  label="得分操作" align="center" ></el-table-column> -->
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewOrderDetail(scope.row, 1)">查看详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewOrderDetail(scope.row, 2)">查看详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${order_total_score.toFixed(2)})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_answer">{{ scope.row.stu_answer.score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="moduleId==91">
                <div class="header">
                    <el-button  size="small" :class="!isorder?'isClick':''" @click="isorder=false">订单管理</el-button>
                    <el-button  size="small" :class="isorder?'isClick':''" @click="isorder=true">评价管理</el-button>
                </div>
                <div v-if="!isorder ">
                <el-table :data="goodsOrderList.slice((orderpage.page - 1) *orderpage.size, orderpage.page * orderpage.size)" class="customTable goods-text-table exam-content"
                    style="width: 100%; flex: 1;">
                    <el-table-column label="考试内容" width="220">
                        <template slot-scope="scope">
                            <span v-if="scope.$index === 0">{{ exam_content }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="order_no"  label="订单编号" align="center" width="220"></el-table-column>
                    <el-table-column prop="scoring_operation"  label="得分操作" align="center" ></el-table-column> -->
                    <el-table-column label="学生提交答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewOrderDetail(scope.row, 1)">查看详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" align="center">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="viewOrderDetail(scope.row, 2)">查看详情
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${order_total_score.toFixed(2)})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.stu_answer">{{ scope.row.stu_answer.score }}</span>
                            <span v-else>学生未提交</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
            <el-pagination
      @current-change="orderpagechange"
      :current-page.sync="orderpage.page"
      :page-size="orderpage.size"
      layout="prev, pager, next, jumper"
      :total="goodsOrderList.length">
    </el-pagination>
        </div>
                 </div>
                 <div v-if="isorder" class="exam_content">
                    <el-table :data="OrderCommentDetail.slice((evalpage.page - 1) *evalpage.size, evalpage.page * evalpage.size)" class="customTable goods-text-table exam-content"
                    style="width: 100%; flex: 1; margin-top: 20px; font-size: 14px;" tooltip-effect="dark">
                    <el-table-column label="考试名称" width="140">
                        <template slot-scope="scope">
                            <span v-if="scope.$index === 0">评价管理</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品信息" :show-overflow-tooltip="true" >
                        <template slot-scope="scope">
                           <div class="goods_info">
                            <img class="good_images" :src="scope.row.good_images" alt="">
                            <div class="goods-title content">
                                {{ scope.row.good_title }}
                                
                                </div>
                           </div>

                        </template>
                    </el-table-column>
                    <el-table-column label="评价内容"  :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="evContent">
                                <div class="content">{{ scope.row.content }}</div>
                               <div class="images">
                                <div v-for="item in scope.row.image" >
                                    <div class="evimg" v-if="item" @click="preview(item)">
                                        <img :src=item alt="">
                                    </div>
                                </div>
                               </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确关键词" width="220" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                          <div class="content">
                            {{scope.row.right_keywords?scope.row.right_keywords:'暂无正确关键词'  }}
                          </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="错误关键词" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                           <div class="content">{{scope.row.wrong_keywords?scope.row.wrong_keywords:'暂无错误关键词'  }}</div>

                            
                        </template>
                    </el-table-column>
                    <el-table-column label="学生提交答案" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="content">{{scope.row.stu_content?scope.row.stu_content:'学生未提交'  }}</div>
                            
                        </template>
                    </el-table-column>
                    <el-table-column :label="`得分(${total_score.toFixed(2)})`" align="center" width="150px">
                        <template slot-scope="scope">
                            <span >{{ scope.row.stu_score }}</span>
                            <!-- <span v-else>学生未提交</span> -->
                        </template>
                    </el-table-column>
                </el-table>
                
                <div class="pagination">
            <el-pagination
      @current-change="evalpagechange"
      :current-page.sync="evalpage.page"
      :page-size="evalpage.size"
      layout="prev, pager, next, jumper"
      :total="OrderCommentDetail.length">
    </el-pagination>
        </div>
                 </div>
            </div>
        </div>
        <!--S 网店管理弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="modifyOrderTitle"
            custom-class="green-dialog02" :visible.sync="modifyOrderDialog" width="500px">
            <el-form :model="modifyOrderForm" ref="modifyOrderForm" label-position="right" label-width="100px">
                <!-- <el-form-item label="订单号:"  prop="order_no">
                    <div class="order-no-title">{{modifyOrderForm.order_no}}</div>
                </el-form-item> -->
                <el-form-item label="发货信息:" prop="order_no">
                    <div class="order-no-title">{{ modifyOrderForm.delivery_information }}</div>
                </el-form-item>
                <el-form-item label="物流公司:" prop="order_no">
                    <div class="order-no-title">{{ modifyOrderForm.delivery_mode }}</div>
                </el-form-item>
                <el-form-item label="物流单号:" prop="order_no">
                    <div class="order-no-title">{{ modifyOrderForm.logistics_number }}</div>
                </el-form-item>
                <div v-if="this.scoringOperation === '出库' || this.scoringOperation === '修改快递单号'">
                    <el-form-item label="快递" prop="delivery_mode">
                        <el-input v-model="modifyOrderForm.delivery_mode" autocomplete="off" style="width:340px"
                            readonly></el-input>
                    </el-form-item>
                    <el-form-item label="单号" prop="logistics_number">
                        <el-input v-model="modifyOrderForm.logistics_number" autocomplete="off" style="width:340px"
                            readonly></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="出库情况" prop="delivery_status"
                    v-if="this.scoringOperation === '退款审核（通过）' || this.scoringOperation === '退款审核（驳回）'">
                    <el-input v-model="modifyOrderForm.delivery_status" autocomplete="off" style="width:340px" readonly>
                    </el-input>
                </el-form-item>
                <el-form-item label="驳回原因" prop="reasons_rejection" v-if="this.scoringOperation === '退款审核（驳回）'">
                    <el-input v-model="modifyOrderForm.reasons_rejection" autocomplete="off" style="width:340px"
                        readonly></el-input>
                </el-form-item>
                <el-form-item label="审核原因" prop="audit_reasons" v-if="this.scoringOperation === '换货审核（通过）' || this.scoringOperation === '退货审核（通过）' ||
                this.scoringOperation === '换货审核（驳回）' || this.scoringOperation === '退货审核（驳回）'">
                    <el-input v-model="modifyOrderForm.audit_reasons" autocomplete="off" style="width:340px" readonly>
                    </el-input>
                </el-form-item>
                <el-form-item label="驳回原因" prop="reasons_rejection"
                    v-if="this.scoringOperation === '换货审核（驳回）' || this.scoringOperation === '退货审核（驳回）'">
                    <el-input v-model="modifyOrderForm.reasons_rejection" autocomplete="off" style="width:340px"
                        readonly></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="modifyOrderDialog = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn()">确 定</el-button>
            </div>
        </el-dialog>
        <!--E 网店管理弹窗-->
        <el-dialog :title="freightTitle" :visible.sync="freightDialogVisible" width="50%">
            <el-form :model="freightAnswer" label-width="80px">
                <el-form-item label="模板名称">
                    <span>{{ freightAnswer.freight_template_title }}</span>
                </el-form-item>
                <el-form-item label="是否包邮">
                    {{ freightAnswer.is_exempt_postage === 0 ? '买家承担' : '卖家承担' }}
                </el-form-item>
                <el-form-item label="计费规则">
                    {{ freightAnswer.accounting_rules === 0 ? '按件数' : '按重量' }}
                </el-form-item>
            </el-form>
            <div class="feight-box">
                <div class="freight-header">除了指定地区外，其余地区的运费采用”默认运费“</div>
                <div class="freight-taps">快递</div>
                <div class="freight-header-th">
                    国内默认运费 <b>{{ freightAnswer.default_num }}</b>
                    件内，<b>{{ freightAnswer.default_price }}</b>元，每增加<b>{{ freightAnswer.add_num }}</b>件，增加运费<b>{{ freightAnswer.add_price }}</b>元
                </div>
                <el-table :data="freightAnswer.freight_setting">
                    <el-table-column prop="city" label="运送到"></el-table-column>
                    <el-table-column prop="first_thing" label="首件（个）"></el-table-column>
                    <el-table-column prop="first_cost" label="首费（元）"></el-table-column>
                    <el-table-column prop="add_thing" label="续件（个）"></el-table-column>
                    <el-table-column prop="add_cost" label="续费（元）"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="imgVisible" width="40%" :before-close="closeDialog">
            <div class="big-img">
                <img :src="imgUrl" alt="">
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { shopManagementDetail,teacheropOrderCommentDetail } from '@/utils/apis'
export default {
    name: "StoreManage",
    data() {
        return {
            examId: Number(this.$route.query.examId) || null,
            studentId: Number(this.$route.query.id) || null,
            type: '',//1是正确答案，2是学生答案
            total_score: 0,//模块得分
            freight_info_total_score: 0,
            //网店管理商品发布
            goodsReleaseList: [],
            //网店管理商品发布
            goodsShootList: [],
            // 网店订单管理
            goodsOrderList: [],
            exam_content: '',
            scoringOperation: '',//得分操作
            order_total_score: 0,//订单模块得分
            //网店管理文字弹窗
            modifyOrderTitle: '',
            //修改快递单号弹窗
            modifyOrderDialog: false,
            modifyOrderForm: {
                order_no: '',
                delivery_mode: '',
                logistics_number: '',
                audit_reasons: '',
                reasons_rejection: '',
                delivery_status: '',
                delivery_information: ''
            },
            scoring_operation: '',
            good_id: '',//商品id
            moduleId: null,
            freightDialogVisible: false,
            freightTitle: '运费详情',
            freightAnswer: {},
            flag: 0,
            role:localStorage.getItem('role'),
            OrderCommentDetail:[],
            isorder:false,
            total_score:0,
            orderpage:{
                page:1,
                size:10
            },
            evalpage:{
                page:1,
                size:6 
            },
            imgVisible:false,
            imgUrl:''
           
        }
    },
    mounted() {
        this.getShopManagementDetail()
        this.getTeacheropOrderCommentDetail()
        if(this.$route.query.moduleId){
            this.moduleId=this.$route.query.moduleId
            // console.log(this.$route.query.moduleId);
        }
       
    },
    methods: {
        gotoDetail(){
            if(this.role==4){
               let res = this.$router.resolve({
                    path:'/judges/goodsDetail',
                    query:{
                        g_id: this.good_id
                    }
                })
                window.open(res.href,"_blank")
            }else if(this.role==6){
                let res = this.$router.resolve({
                    path:'/center/goods/detail',
                    query:{
                        g_id: this.good_id
                    }
                })
                window.open(res.href,"_blank")
            }
        },
        //获取网店详情
        getShopManagementDetail() {
            let params = {
                exam_id: this.examId,
                student_id: this.studentId,
            }
            shopManagementDetail(params).then((res) => {
                // console.log('res', res);
                if (res.data === 15) {
                    this.exam_content = '网店商品发布与管理';
                    this.goodsReleaseList = [];
                    this.goodsShootList = [];
                } else if (res.data === 16) {
                    this.exam_content = '移动平台商品发布与管理';
                    this.goodsReleaseList = [];
                    this.goodsShootList = [];
                } else if (res.data === 18) {
                    this.exam_content = '订单管理';
                    this.goodsOrderList = [];
                }
                else {
                    if (res.data.goods_info) {
                        this.goodsReleaseList.push(res.data.goods_info)
                        this.total_score = res.data.goods_info.score;
                        this.good_id = res.data.goods_info.id;
                    }
                    if (res.data.freight_info) {
                        this.goodsShootList.push(res.data.freight_info)
                        this.freight_info_total_score = res.data.freight_info.score;
                    }
                    if (res.data.order_list) {
                        this.goodsOrderList = res.data.order_list;
                        let total_score = 0;
                        this.goodsOrderList.map(item => {
                            if (item.stu_answer) {
                                total_score += Number(item.stu_answer.score);
                            }
                        });
                        this.order_total_score = total_score;
                        this.exam_content = res.data.exam_content
                    }
                    if (res.data.freight_info) {
                        this.exam_content = res.data.freight_info.exam_content
                    } else if(res.data.goods_info) {
                        this.exam_content = res.data.goods_info.exam_content
                    }else{
                        this.exam_content=res.data.exam_content
                    }
                }
            }).catch((err) => {
                console.error('err', err);
            });
        },
        // 获取到评价管理的信息
        getTeacheropOrderCommentDetail(){
            let param={
                exam_id: this.examId,
                student_id: this.studentId,
            }
            teacheropOrderCommentDetail(param).then(res=>{
                this.OrderCommentDetail=res.data.list
                this.total_score=res.data.total_score
                console.log(this.OrderCommentDetail);

            })
        },
        //查看详情网店商品发布与管理-移动商品-正确答案
        viewDetailGoods(row) {
            if (this.$route.path === '/examCenter/trainingData/storeManage') {
                this.$router.push({
                    path: '/examCenter/trainingData/freightDetail',
                    query: {
                        type: 1,
                        examId: this.examId,
                        studentId: this.studentId,
                    }
                })
            } else {
                this.freightDialogVisible = true;
                this.freightTitle = '正确答案（运费详情）';
                this.freightAnswer = row.answer;
                /*this.$router.push({
                    path: '/examCenter/examData/freightDetail',
                    query:{
                        type:1,
                        examId:this.examId,
                        studentId:this.studentId,
                    }
                })*/
                // console.log('row', row);

            }
        },
        //查看详情网店商品发布与管理-移动商品-学生提交答案
        viewDetailGoodsStu(row) {
            if (this.$route.path === '/examCenter/trainingData/storeManage') {
                this.$router.push({
                    path: '/examCenter/trainingData/freightDetail',
                    query: {
                        type: 2,
                        examId: this.examId,
                        studentId: this.studentId,
                    }
                })
            } else {
                this.freightDialogVisible = true;
                this.freightTitle = '学生运费详情';
                this.freightAnswer = row.stu_answer;
                /*this.$router.push({
                    path: '/examCenter/examData/freightDetail',
                    query:{
                        type:2,
                        examId:this.examId,
                        studentId:this.studentId,
                    }
                })*/

            }
        },
        //网店订单 type=1 学生 2 答案
        viewOrderDetail(row, type) {
            this.modifyOrderDialog = true;
            if (type == 1) {
                this.modifyOrderTitle = '查看学生答案'
                this.flag = 1
                if (row.stu_answer) {
                    this.modifyOrderForm.delivery_information = row.stu_answer.delivery_information;
                    this.modifyOrderForm.delivery_mode = row.stu_answer.logistics;
                    this.modifyOrderForm.logistics_number = row.stu_answer.logistics_number
                } else {
                    this.modifyOrderForm.delivery_information = '暂无数据';
                    this.modifyOrderForm.delivery_mode = '暂无数据';
                    this.modifyOrderForm.logistics_number = '暂无数据'
                }
            }
            if (type == 2) {
                this.modifyOrderTitle = "查看正确答案"
                this.flag = 2
                this.modifyOrderForm.delivery_information = row.answer.delivery_information;
                this.modifyOrderForm.delivery_mode = row.answer.delivery_mode;
                this.modifyOrderForm.logistics_number = row.answer.logistics_number
            }
            // this.scoringOperation = row.scoring_operation
            // this.modifyOrderForm.order_no = row.order_no
            // if (this.scoringOperation === '换货审核（驳回）') {
            //     this.modifyOrderTitle = '换货审核（驳回）';
            //     if (type === 1) {
            //       //学生答案
            //       this.modifyOrderTitle='查看学生答案'
            //       this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
            //       this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
            //     }
            //     if (type === 2) {
            //         this.modifyOrderTitle='查看正确答案'
            //         this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
            //         this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
            //     }

            // }
            // if (this.scoringOperation === '出库') {
            //     this.modifyOrderTitle = '出库'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.delivery_mode = row.stu_answer.logistics;
            //             this.modifyOrderForm.logistics_number = row.stu_answer.logistics_number;
            //         }
            //     }else {
            //         this.modifyOrderForm.delivery_mode = row.answer.logistics;
            //         this.modifyOrderForm.logistics_number = row.answer.logistics_number;
            //     }
            // }
            // if (this.scoringOperation === '修改快递单号') {
            //     this.modifyOrderTitle = '修改快递单号'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.delivery_mode = row.stu_answer.logistics;
            //             this.modifyOrderForm.logistics_number = row.stu_answer.logistics_number;
            //         }
            //     }else {
            //         this.modifyOrderForm.delivery_mode = row.answer.logistics;
            //         this.modifyOrderForm.logistics_number = row.answer.logistics_number;
            //     }
            // }
            // if (this.scoringOperation === '退款审核（通过）') {
            //     this.modifyOrderTitle = '退款审核单审核'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.delivery_status = row.stu_answer.delivery_status;
            //             this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
            //         }
            //     }else {
            //         this.modifyOrderForm.delivery_status = row.answer.delivery_status;
            //         this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
            //     }
            // }
            // if (this.scoringOperation === '退款审核（驳回）') {
            //     this.modifyOrderTitle = '退款审核单审核'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.delivery_status = row.stu_answer.delivery_status;
            //             this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
            //         }
            //     }else {
            //         this.modifyOrderForm.delivery_status = row.answer.delivery_status;
            //         this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
            //     }
            // }
            // if (this.scoringOperation === '退货审核（驳回）') {
            //     this.modifyOrderTitle = '退货审核'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
            //             this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
            //         }
            //     }else {
            //         this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
            //         this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
            //     }
            // }
            // if (this.scoringOperation === '退货审核（通过）') {
            //     this.modifyOrderTitle = '退货审核'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
            //         }
            //     }else {
            //         this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
            //     }
            // }
            // if (this.scoringOperation === '换货审核（通过）') {
            //     this.modifyOrderTitle = '换货审核'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
            //         }
            //     }else {
            //         this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
            //     }
            // }
            // if (this.scoringOperation === '退货审核（驳回）') {
            //     this.modifyOrderTitle = '换货审核'
            //     if (type === 1){
            //         if (row.stu_answer){
            //             this.modifyOrderForm.audit_reasons = row.stu_answer.audit_reasons;
            //             this.modifyOrderForm.reasons_rejection = row.stu_answer.reasons_rejection;
            //         }
            //     }else {
            //         this.modifyOrderForm.audit_reasons = row.answer.audit_reasons;
            //         this.modifyOrderForm.reasons_rejection = row.answer.reasons_rejection;
            //     }
            // }
        },
        // 修改快递单号确定事件
        addOrderBtn() {
            this.modifyOrderDialog = false;
        },
        // 订单管理分页
        orderpagechange(val){
            this.orderpage.page=val
        },

        // 评价管理分页
        evalpagechange(val){
            this.evalpage.page=val
        },
         // 点击放大图片预览
         preview(url){
            this.imgVisible=true
            this.imgUrl=url
        },
        closeDialog(){
            this.imgVisible=false
            this.imgUrl=''
        
        }

    }
}
</script>

<style scoped lang="scss">
.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 20px 20px 20px;

    .main-wrapper-table {
        display: flex;
        flex-direction: column;
        height: 100%;

        .main-wrapper-scrollbar {
            height: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }

    .goods-text-table {
        .answer {
            display: flex;
            align-items: center;

            img {
                width: 80px;
                height: 80px;
                margin-right: 15px;
            }
        }

        .a-link {
            height: 80px;
            display: flex;
            align-items: center;

            .text {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }

    .dialog-footer {
        text-align: center;
        margin-top: 20px;
    }

    /*.order-main{*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    height: 100%;*/
    /*}*/
    .header{
        margin: 20px 0;
    }
    .isClick{
        background-color:#1CB064;
        color: #fff;
    }
}
.goods_info{
    display: flex;
    font-size: 14px;
   
    .good_images{
    width: 50px;
    height: 50px;
    // display: block;
}.goods-title{
    height: 50px;
    margin-left: 10px;
}
}
.evContent{
    font-size: 14px;
    .images{
        display: flex;
       
        div{
            // width: 40px;
            // height: 40px;
            .evimg{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            img{
                width: 100%; /* 图片宽度占满容器 */
                height: 100%; /* 图片高度占满容器 */
                object-fit: cover; /* 图片不变形，占满整个盒子 */
            }
       
        }
        }
    }
}
::v-deep .el-table__row td {
vertical-align: top;//不垂直居中
margin-top: 10px;
}
.pagination{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.exam_content{
    // overflow-y: auto;
    // height: 716px;
}
.content{
    width:100%;
    // height:70px;
    overflow: hidden; /* 隐藏溢出的内容 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
// display: -webkit-box; /* 使用旧的 WebKit 语法 */
//   -webkit-box-orient: vertical; /* 垂直方向排列 */
//   overflow: hidden; /* 隐藏溢出的内容 */
//   -webkit-line-clamp: 1; /* 显示的行数 */
}
.big-img{
    width: 100%;
    height: 500px;
    // background-color: pink;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

</style>